/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

/* Quill */
@import "~quill/dist/quill.snow.css";

// regular style toast
@import "ngx-toastr/toastr";

[dir="rtl"] body {
  .toast-top-right {
    top: 12px;
    left: 12px;
    right: unset;
  }
}

[dir="ltr"] body {
  .toast-top-right {
    top: 12px;
    right: 12px;
    left: unset;
  }
}
