/* -------------------------------------------------------------------------- */
/*  @ Checkbox
/* -------------------------------------------------------------------------- */
.mat-mdc-checkbox {
    .mdc-form-field {
        padding-right: unset;
        @apply pe-3;
    }
}


/* -------------------------------------------------------------------------- */
/*  @ Form fields
/* -------------------------------------------------------------------------- */

/* "fill" appearance */
.mat-mdc-form-field.mat-form-field-appearance-fill {


    /* Remove the default arrow for native select */
    &.mat-mdc-form-field-type-mat-native-select {

        .mat-mdc-form-field-infix {

            [dir="rtl"] select {
                padding-right: unset;
                @apply pe-4;
                background-position: left -7px center;
            }


        }
    }

    /* Default style tweaks and enhancements */
    .mat-mdc-text-field-wrapper {

        /* Form field */
        .mat-mdc-form-field-flex {


            .mat-mdc-form-field-icon-prefix,
            .mat-mdc-form-field-text-prefix {

                > .mat-icon {
                    margin-right: unset;
                    @apply pe-3;
                }

                > .mat-mdc-icon-button {
                    //margin: 0 4px 0 -10px;
                    margin: unset;
                    @apply my-0 ms-1 -me-2;
                }

                > .mat-mdc-select {
                    //margin-right: 10px;
                    margin-right: unset;
                    @apply me-2;
                }

                > .mat-datepicker-toggle {
                    //margin-left: -8px;
                    margin-left: unset;
                    @apply -ms-2;
                }
            }

            .mat-mdc-form-field-icon-suffix,
            .mat-mdc-form-field-text-suffix {
                padding: 0 !important;

                > .mat-icon {
                    margin-left: 12px;
                    @apply ms-3;
                }

                > .mat-mdc-icon-button {
                    //margin: 0 -10px 0 4px;
                    margin: unset;
                    @apply my-0 -ms-2 me-1
                }

                > .mat-mdc-select {
                    //margin-left: 10px;
                    margin-left: unset;
                    @apply ms-2;
                }

                > .mat-datepicker-toggle {
                    //margin-right: -8px;
                    margin-right: unset;
                    @apply -me-2;
                }
            }

            .mat-mdc-form-field-icon-prefix,
            .mat-mdc-form-field-text-prefix,
            .mat-mdc-form-field-icon-suffix,
            .mat-mdc-form-field-text-suffix {

                /* Make mat-select usable as prefix and suffix */
                .mat-mdc-select {
                    .mat-mdc-select-trigger {
                        .mat-mdc-select-arrow-wrapper {
                            //margin-left: 4px;
                            margin-left: unset;
                            @apply ms-1;

                        }
                    }
                }
            }

            /* Infix */
            .mat-mdc-form-field-infix {


                /* Floating label - disable floating action */
                .mat-mdc-floating-label {
                    left: unset !important;
                    @apply start-0;
                }

                /* Textarea */
                textarea.mat-mdc-input-element {
                    //padding: 0 6px 0 0;
                    padding: unset;
                    @apply my-0 ms-1 me-0;
                }


            }
        }

    }


    /* Rounded */
    &.fuse-mat-rounded {


        /* Emphasized affix */
        &.fuse-mat-emphasized-affix {

            .mat-mdc-text-field-wrapper {

                .mat-mdc-form-field-flex {

                    .mat-mdc-form-field-icon-prefix,
                    .mat-mdc-form-field-text-prefix {
                        > .mat-icon {
                            //margin-right: 12px;

                            @apply ms-3;
                        }

                        > .mat-mdc-icon-button {
                            //margin: 0 2px 0 -10px !important;
                            margin: unset !important;
                            @apply my-0 ms-1 me-2;
                        }

                        > .mat-mdc-select {
                            //margin-right: 8px;
                            margin-right: unset;
                            @apply me-2;
                        }

                        > .mat-datepicker-toggle {
                            //margin-right: 4px;
                            margin-right: unset;
                            @apply me-1;
                        }

                        > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
                            //margin-right: 12px;
                            margin-right: unset;
                            @apply me-3;
                        }
                    }

                    .mat-mdc-form-field-icon-suffix,
                    .mat-mdc-form-field-text-suffix {
                        > .mat-icon {
                            //margin-left: 12px !important;
                            margin-left: unset !important;
                            @apply ms-3;
                        }

                        > .mat-mdc-icon-button {
                            //margin: 0 -10px 0 2px !important;
                            margin: unset !important;
                            @apply my-0 -ms-2 me-1;
                        }

                        > .mat-mdc-select {
                            //margin-left: 12px !important;
                            margin-left: unset !important;
                            @apply ms-3;
                        }

                        > .mat-datepicker-toggle {
                            //margin-left: 4px !important;
                            margin-left: unset !important;
                            @apply ms-1;
                        }

                        > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
                            //margin-left: 12px !important;
                            margin-left: unset !important;
                            @apply ms-3;
                        }
                    }
                }
            }
        }
    }

    /* Dense */
    &.fuse-mat-dense {
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-icon-prefix,
                .mat-mdc-form-field-text-prefix {
                    > .mat-mdc-icon-button {
                        /*  margin-left: -6px;
                          margin-right: 12px;*/
                        @apply -ms-2 me-3;
                    }
                }

                .mat-mdc-form-field-icon-suffix,
                .mat-mdc-form-field-text-suffix {

                    > .mat-mdc-icon-button {
                        /*      margin-left: 12px;
                              margin-right: -6px;*/
                        @apply ms-3 -me-2;
                    }
                }


            }
        }

    }

    /* Emphasized affix */
    &.fuse-mat-emphasized-affix {

        .mat-mdc-text-field-wrapper {

            .mat-mdc-form-field-flex {

                .mat-mdc-form-field-icon-prefix,
                .mat-mdc-form-field-text-prefix {
                    //margin: 0 16px 0 -16px !important;
                    margin: unset !important;
                    //padding-left: 16px !important;
                    padding-left: unset !important;
                    //border-right-width: 1px;
                    border-right-width: unset;
                    @apply border-e ps-4 my-0 ms-4 -me-4;

                    > .mat-icon {
                        //margin-right: 16px;
                        margin-right: unset;
                        @apply me-4;
                    }

                    > .mat-mdc-icon-button {
                        //margin: 0 6px 0 -10px !important;
                        margin: unset !important;
                        @apply my-0 ms-1 -me-2;
                    }

                    > .mat-mdc-select {
                        //margin-right: 12px !important;
                        margin-right: unset !important;
                        @apply me-3;
                    }

                    > .mat-datepicker-toggle {
                        //margin-right: 8px;
                        margin-right: unset;
                        @apply me-2;
                    }

                    > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
                        //margin-right: 16px;
                        margin-right: unset;
                        @apply me-4;
                    }
                }

                .mat-mdc-form-field-icon-suffix,
                .mat-mdc-form-field-text-suffix {
                    //margin: 0 -16px 0 16px !important;
                    //padding-right: 16px !important;
                    //border-left-width: 1px;
                    margin: unset !important;
                    padding-right: unset !important;
                    border-left-width: unset;
                    @apply my-0 -ms-4 me-4 pe-4 border-s;

                    > .mat-icon {
                        //margin-left: 16px;
                        margin-left: unset;
                        @apply ms-4;
                    }

                    > .mat-mdc-icon-button {
                        //margin: 0 -10px 0 6px !important;
                        margin: unset  !important;
                        @apply my-0 -ms-2 me-1;
                    }

                    > .mat-mdc-select {
                        //margin: 0 -4px 0 16px !important;
                        margin: unset !important;
                        @apply my-0 -ms-1 me-4;
                    }

                    > .mat-datepicker-toggle {
                        //margin-left: 8px;
                        margin-left: unset;
                        @apply ms-2;
                    }

                    > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(.mat-datepicker-toggle) {
                        //margin-left: 16px;
                        margin-left: unset;
                        @apply ms-4;
                    }
                }


            }
        }

    }


}


/* -------------------------------------------------------------------------- */
/*  @ Select
/* -------------------------------------------------------------------------- */
.mat-mdc-select {
    .mat-mdc-select-arrow-wrapper {
        .mat-mdc-select-arrow {
            //margin: 0 0 0 8px;
            margin: unset;
            @apply my-0 ms-0 me-2;
        }
    }
}


