/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[dir="rtl"] body,
[dir="ltr"] body {
  .mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-icon-suffix
    > .mat-mdc-icon-button,
  .mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-text-suffix
    > .mat-mdc-icon-button {
    margin: 0 4px 0 -10px !important;
  }
  .mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-icon-prefix
    > .mat-mdc-icon-button,
  .mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-text-prefix
    > .mat-mdc-icon-button {
    margin: 0 -10px 0 4px !important;
  }

  .mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-icon-suffix
    > .mat-icon,
  .mat-mdc-form-field.mat-form-field-appearance-fill
    .mat-mdc-text-field-wrapper
    .mat-mdc-form-field-flex
    .mat-mdc-form-field-text-suffix
    > .mat-icon {
    margin-right: 12px;
    margin-left: unset;
  }

  .mdc-floating-label {
    overflow: unset;
  }
}

pre {
  direction: ltr;
  text-align: left;
}

// Debug View
.debug {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 200px;
  min-height: 100px;
  background-color: #000;
  opacity: 0.5;
  color: #fff;
  z-index: 9999999;
  padding: 8px;
}

.my-debugger {
  position: fixed;
  z-index: 3231321;
  bottom: 0;
  right: 0;
  min-width: 200px;
  max-width: 70%;

  direction: ltr;

  .head {
    @apply flex flex-row;
    .close {
      @apply px-2 ml-1;
      //@apply absolute top-0 ;
      //transform: translateY(-100%);
      background-color: #112233dd;
      color: white;
      border-radius: 4px 4px 0 0;
    }
  }

  .body {
    min-height: 100px;
    max-height: 400px;
    border: solid 2px #000;
    border-radius: 2px;
    background-color: #111;
    opacity: 0.6;
    color: #fff;
    padding: 8px;
    overflow: auto;

    .d-val {
      font-weight: bold;
    }
  }
}
